<template>
  <div>
    <nav>
      <div class="left">
        <el-image
          style="width: 195px; height: 46px;margin-right:30px"
          :src="require('@/util/images/user/pic_logo_grzx.png')"
          fit="contain"
        ></el-image>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
          text-color="#333333"
          active-text-color="#0383E4"
        >
          <el-menu-item index="/edit">编辑</el-menu-item>
          <el-menu-item index="/inbox">收件箱</el-menu-item>
          <el-menu-item index="/soncompany">附属公司</el-menu-item>
          <el-menu-item index="/statistical">统计数据</el-menu-item>
          <el-menu-item index="/shocang">收藏</el-menu-item>
        </el-menu>
      </div>
      <div class="right">
        <el-menu
          :default-active="activeIndex1"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect1"
          :router="true"
          text-color="#333333"
          active-text-color="#0383E4"
        >
          <el-menu-item index="/upgradevip">升级到企业版</el-menu-item>
          <el-menu-item @click="changemo">选择模板</el-menu-item>
          <el-menu-item index="/paymenthistory">付款记录</el-menu-item>
          <el-menu-item index="/accountsetting">
            <el-avatar
              style="margin-right:10px"
              size="small"
              v-if="infolist.avatar"
              :src="infolist.avatar"
            ></el-avatar
            >账号设置</el-menu-item
          >
          <el-menu-item class="last" @click="goout">退出</el-menu-item>
        </el-menu>
      </div>
    </nav>
    <router-view ref="target-name" />
    <el-dialog
      title="选择模板"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <div class="mobanlist">
        <div
          @mouseover="changsrcoll(index)"
          @click="temchange(item.id, item.level_type)"
          class="temitem"
          :key="index"
          v-for="(item, index) in temlist"
        >
          <el-image
            class="animate__animated animate__backInUp"
            :src="item.image"
          ></el-image>
          <div
            v-if="Number(item.level_type) === 2 && Number(infolist.level) === 0"
            class="vip"
          >
            账号升级
          </div>
          <div
            v-if="
              Number(item.level_type) === 3 &&
                (Number(infolist.level) === 0 || Number(infolist.level) === 1)
            "
            class="vip"
          >
            账号升级
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userinfo, wxlogin1 } from "../../api/userapi";
import { template, templateselect, layoutbg } from "../../api/iphone";
export default {
  name: "usercenter",

  components: {},

  data() {
    return {
      dialogVisible: false,
      activeIndex: "/edit",
      activeIndex1: "",
      infolist: "",
      temlist: [],
      active: "",
      info: ""
    };
  },

  mounted() {
    this.userid = window.localStorage.getItem("infortree-userid");
    this.userinfo = window.localStorage.getItem("infortree-userinfo");

    if (this.$route.query.code !== undefined) {
    } else {
      if (this.userid === null) {
        this.$router.push("/login");
      }
      if (this.userid === undefined) {
        this.$router.push("/login");
      }
    }
    this.getuserinfo();
    this.gettemplatelist();
  },

  methods: {
    geturl() {
      const that = this;
      wxlogin1({ code: this.code }).then(res => {
        if (res.code === 1) {
          const info = JSON.stringify(res.info);
          window.localStorage.setItem("infortree-userinfo", info);
          window.localStorage.setItem("infortree-userid", res.info.id);
          setTimeout(res1 => {
            that.$message.success(res.msg);
            that.userid = window.localStorage.getItem("infortree-userid");
            that.useronfo = window.localStorage.getItem("infortree-userinfo");
            that.useronfo = JSON.parse(that.useronfo);
            that.getbutton();
            that.getuserinfo();
            that.getinfolist();
            const username = that.data1.user.username;
            that.url = "http://" + username + ".infortree.com/";
          }, 1000);
        } else {
          setTimeout(res1 => {
            that.$message.error(res.msg);
          }, 1000);
        }
      });
    },
    temchange(e, LEVEL) {
      if (Number(LEVEL) === 2 && Number(this.infolist.level) === 0) {
        return this.$message({
          type: "error",
          message: "请先升级您的账号!"
        });
      }
      if (
        Number(LEVEL) === 3 &&
        (Number(this.infolist.level) === 0 || Number(this.infolist.level) === 1)
      ) {
        return this.$message({
          type: "error",
          message: "请先升级您的账号!"
        });
      }
      this.$confirm("您当前的所有内容和设计都将被替换。", "你确定吗？", {
        confirmButtonText: "是的，安装此模板并删除我当前的内容",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          templateselect({ type_id: e, user_id: this.userid }).then(res => {
            if (res.code) {
              this.$message({
                type: "success",
                message: "选择成功!"
              });
              this.dialogVisible = false;
              this.$refs["target-name"].getuserinfo();
              this.$refs["target-name"].getinfolist();
            } else {
              this.$message({
                type: "error",
                message: res.msg
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    changsrcoll(e) {
      this.active = e;
    },
    gettemplatelist() {
      template().then(res => {
        if (res.code === 1) {
          this.temlist = res.list;
        }
      });
    },
    handleClose(done) {
      if (this.info === false) {
        done();
        return this.$router.push("/accountsetting");
      }
      done();
    },
    changemo() {
      this.dialogVisible = true;
    },
    getuserinfo() {
      userinfo({ user_id: this.userid }).then(res => {
        if (res.code === 1) {
          this.infolist = res.list;
        }
      });
      layoutbg({ user_id: this.userid }).then(res => {
        if (res.code === 1) {
          if (res.info === "") {
            this.info = false;
          }
        }
      });
    },
    goout() {
      this.$confirm("请确定是否退出?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          window.localStorage.removeItem("infortree-userinfo");
          window.localStorage.removeItem("infortree-userid");
          this.$message({
            type: "success",
            message: "退出成功!"
          });
          this.$router.push("/");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出"
          });
        });
    },
    handleSelect(key, keyPath) {
      this.activeIndex = "";
      this.activeIndex1 = "";
      this.activeIndex = key;
    },
    handleSelect1(key, keyPath) {
      this.activeIndex = "";
      this.activeIndex1 = "";
      this.activeIndex1 = key;
    }
  }
};
</script>
<style lang="less" scoped>
.mobanlist {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-height: 700px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  padding: 30px;
  .temitem {
    width: 13%;
    height: 324px;
    margin-bottom: 20px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .el-image {
      position: absolute;
      top: 0px;
      z-index: 10;
    }
    .vip {
      z-index: 11;
      position: absolute;
      color: #fff;
      background-color: #0383e4;
      text-align: center;
      font-size: 14px;
      width: 70px;
      height: 25px;
      line-height: 25px;
      top: 0;
      right: 0;
    }
  }
}
.scale-down-ver-top {
  -webkit-animation: scale-down-ver-top 1s ease both;
  animation: scale-down-ver-top 1s ease both;
}
@-webkit-keyframes scale-down-ver-top {
  0% {
    top: 0px;
    bottom: auto;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    bottom: 0px;
    top: auto;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-down-ver-top {
  0% {
    top: 0px;
    bottom: auto;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    bottom: 0px;
    top: auto;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.last::after {
  content: "";
  height: 0px !important;
}
.right .el-menu-item {
  position: relative;
}
.right .el-menu-item::after {
  position: absolute;
  content: "";
  right: 0;
  height: 24px;
  border-right: 1px solid #ddd;
  top: 0;
  bottom: 0;
  margin: auto;
}
/deep/.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0px;
}
nav {
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 3px 2px 0px rgba(200, 200, 200, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  .left {
    display: inline-block;
    display: flex;
    align-items: center;
  }
  .right {
    display: inline-block;
    display: flex;
    align-items: center;
  }
}
</style>
